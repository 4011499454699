/* Margin */
@for $i from 0 through 50 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

/* Padding */
@for $i from 0 through 50 {
  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --vermelho: #fa4645;
  --vermelho-escuro: #b53333;
  --branco: #FFF;
  --cinza-escuro: #2b2b2b;
  --verde-claro: #39b9c6;
}

.lexend-100 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.lexend-200 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.lexend {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.lexend-400 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.lexend-500 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.lexend-600 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.lexend-700 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.lexend-800 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

/* GLOBAL */

html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

/* HEADER */

.linhaLogo {
  display: grid;
  place-items: center;
}

.logo {
  position: relative;
  min-height: 200px;
  text-align: center;
}

.logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
}

/* MENU */

.bg-semana-2023 {
  background: var(--vermelho);
}

.buttonMenu {
  color: var(--branco);
  font-weight: 600;
}

.buttonMenu a {
  color: var(--branco);
  font-weight: 600;
}

.buttonMenu a::after {
  color: var(--branco);
  font-weight: 600;
}

.buttonMenu a:active {
  color: var(--branco);
  font-weight: 600;
}

.buttonMenu:hover {
  color: var(--branco);
  font-weight: 600;
}

.navbar ul li a {
  color: var(--branco) !important;
  font-weight: 600;
}

.navbar-nav .nav-item .nav-link.active {
  background-color: var(--verde-claro);
  color: var(--branco) !important;
  font-weight: 600;
}

.navbar-nav .nav-item .nav-link:hover {
  background-color: var(--vermelho-escuro);
  color: var(--branco);
  font-weight: 600;
}

.nav-link.show {
  color: white;
}

.nav-link {
  height: 100%;
}

.dropdown-menu {
  background: var(--vermelho);
}

.dropdown-item:hover {
  background: var(--vermelho-escuro);
}

/* SLIDE */

.foto {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.fotoSlide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* LAST POSTS */

.container-fluid {
  min-height: 50px;
}

.card-title-posts {
  padding: 10px;
  padding-left: 0;
}

.espacamentoEntreLinhas {
  margin-top: 20px !important;
}

.labelLastPosts {
  background: var(--vermelho);
  padding: 2px;
  margin-bottom: 20px;
  color: var(--branco);
  margin-top: 30px;
}

.labelLastPosts h5 {
  margin-left: 10px;
}

/* MORE VIEWS POSTS */

.labelMoreViewsPosts {
  background: var(--vermelho);
  padding: 2px;
  margin-bottom: 10px;
  color: var(--branco);
}

.labelMoreViewsPosts h5 {
  margin-left: 10px;
}

/* FOOTER */

.footer {
  margin-top: 15em;
  min-height: 20em;
  background: var(--cinza-escuro);
}

.acesso {
  width: 15em;
}

.social {
  width: 5em;
  padding: 10px;
  margin-bottom: 30px;
}

/* COMECOU AQUI - WEBSITE-PANEL */
.menu {
  background: #EEEEEE;
  height: 100vh;
}

.menu-internal {
  padding: 0px;
  height: 70vh;
}

@media only screen and (min-width: 768px) {
  .menu-internal {
    height: 100vh;
  }
}

.content-internal {
  padding: 20px;
}

.text-small {
  font-size: 14px;
}

.dropdown-teste {
  width: 100%;
  margin-bottom: 10px;
}

.teste {
  position: relative;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.overlay-button {
  background-color: #fff;
  color: #333;
  border: 2px solid #333;
  border-radius: 5px;
  font-size: 16px;
  transition: opacity 0.8s ease;
  opacity: 0;
}

.overlay:hover .overlay-button {
  opacity: 1;
}

.overlay:hover {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.component-edit {
  background: #EEEEEE;
  padding: 10px;
}

.component-edit-white {
  border: 1px solid #CCC;
  border-radius: 10px;
  padding: 10px;
}

.button {
  cursor: pointer
}

.ql-container {
  min-height: 150px;
}

.dropzone {
  text-align: center;
  border: 2px dashed gray;
  position: relative;
  min-height: 250px;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.dropzone-image {
  margin-top: 10px;
  max-width: 200px;
  max-height: 200px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  color: #333;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-weight: 700;
  cursor: pointer;
}

.overlay.visible {
  opacity: 1;
}

.overlay p {
  padding: 20px;
}

.botao-menu {
  width: 40px;
  height: 40px;
  border: 1px solid #CCC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
}

.botao-expandido {
  top: 1vh;
  left: 14vw;
  background: #FFF;
}

.botao-recolhido {
  top: 10px;
  left: 10px;
}

.conteudo-recolhido {
  margin-left: 50px;
}

.box-default {
  background: #EEEEEE;
  border-radius: 5px;
}

.box-site-edit {
  border: 2px dashed gray;
}

#dropdown-basic-button {
  width: 100% !important;
}

.edit-item {
  border: 1px solid #CCC;
  padding: 20px;
  border-radius: 5px;
}

.accordion-light .accordion-button {
  background-color: #f8f9fa;  /* Cor de fundo light */
  color: #212529;             /* Cor do texto escura */
}

.accordion-light .accordion-button:not(.collapsed) {
  background-color: #495057;  /* Cor quando está expandido */
  color: white;
}

.accordion-light .accordion-body {
  background-color: #ffffff;  /* Cor de fundo do corpo do Accordion */
}

.uploadImage {
  cursor: pointer;
  text-align: center;
  padding: 20px;
  border: 2px dashed gray;
}

.remove-item {
  cursor: pointer;
}

.arrow-carousel {
  cursor: pointer;
}